import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import PaperList from "../components/paper-list"
import FeaturedMarker from "../components/featured-marker"
import {
    faChalkboardTeacher, faUserGraduate, faPenNib, faUniversity, faTrophy, faHandsHelping, faBookmark
} from "@fortawesome/free-solid-svg-icons"
import {
    tableOfContents,
    subtitle,
    titleInfo,
    enumeration,
    urlBadge,
    date,
    info,
    additionalInfo,
    item,
    metadata,
    title,
    contentBadge, tableOfContentsList
} from "./academic.module.scss"

const getYears = (papers) => {
    return papers.map(({ node }) => node.date.substring(0, 4)).filter((v, i, a) => a.indexOf(v) === i)
}

const MediaPage = ({ data }) => {
    const papers = data.allPapersJson.edges.filter(e => !e.node.hidden)
    const yearsOfPapers = getYears(papers)

    return (<>
        <Seo title="Academic"/>

        <h1>Academic</h1>

        <section className={tableOfContents}>
            <h2>
                <FontAwesomeIcon icon={faBookmark}
                                 className={"header-icon"}
                                 size={"sm"}/>
                Table of Contents
            </h2>
            <div>
                <ul className={"fa-ul " + tableOfContentsList}>
                    <li>
                        <FontAwesomeIcon icon={faUniversity} className={"header-icon"} listItem/>
                        <AnchorLink to="/academic#position">
                            Position
                            <span className={contentBadge}>Post-doctoral researcher at KU Leuven</span>
                        </AnchorLink>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faPenNib} className={"header-icon"} listItem/>
                        <AnchorLink to="/academic#publications">
                            Publications
                            <span className={contentBadge}>{papers.length} papers</span>
                        </AnchorLink>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faUserGraduate} className={"header-icon"} listItem/>
                        <AnchorLink to="/academic#education">
                            Education
                            <span className={contentBadge}>{data.allEducationJson.edges.length} studies</span>
                        </AnchorLink>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faChalkboardTeacher} className={"header-icon"} listItem/>
                        <AnchorLink to="/academic#teaching">
                            Teaching
                            <span className={contentBadge}>{data.allTeachingJson.edges.length} courses</span>
                        </AnchorLink>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faHandsHelping} className={"header-icon"} listItem/>
                        <AnchorLink to="/academic#supervision">
                            Supervision
                            <span className={contentBadge}>{data.allSupervisionJson.edges.length} students</span>
                        </AnchorLink>

                    </li>
                    <li>
                        <FontAwesomeIcon icon={faTrophy} className={"header-icon"} listItem/>
                        <AnchorLink to="/academic#awards">
                            Awards
                            <span className={contentBadge}>{data.allAwardsJson.edges.length} awards</span>
                        </AnchorLink>
                    </li>
                </ul>
            </div>
        </section>

        <section id="position">
            <h2>
                <FontAwesomeIcon icon={faUniversity} className={"header-icon"}/>
                Position
            </h2>
            <p>
                <span>I am a post-doctoral researcher working on creative artificial intelligence</span>
                {" "} under supervision of {" "}
                <a href="https://people.cs.kuleuven.be/~luc.deraedt/" target="_blank" rel="noopener noreferrer">
                    prof. dr. Luc De Raedt
                </a>
                {" "} at the {" "}
                <a href="https://dtai.cs.kuleuven.be/" target="_blank" rel="noopener noreferrer">
                    DTAI research group
                </a>
                {" "} of the {" "}
                <a href="https://kuleuven.be/" target="_blank" rel="noopener noreferrer">
                    KU Leuven
                </a>
                {" "}
                <a href="https://wms.cs.kuleuven.be/cs/english" target="_blank" rel="noopener noreferrer">
                    computer science department
                </a>
                {" "} and the {" "}
                <a href="https://ai.kuleuven.be/" target="_blank" rel="noopener noreferrer">
                    Leuven.AI institute
                </a>.
            </p>

        </section>

        <section id="publications">
            <h2>
                <FontAwesomeIcon icon={faPenNib} className={"header-icon"}/>
                Publications
            </h2>
            <p>
                During my academic career, I have published the following {papers.length} papers.
                A more up-to-date list is available on <a
                href="https://scholar.google.com/citations?user=lHfPiwoAAAAJ" target="_blank" rel="noopener noreferrer">my
                Google
                Scholar profile</a>. <br/>

                <em className="text-secondary">
                    <FeaturedMarker featured={true}/> Papers marked in blue are recommended if you would like
                    to gain quick insights into my main research interests.
                </em>
            </p>
            {yearsOfPapers.map(year => (<div key={year}>
                <h3>{year}</h3>
                <PaperList papers={papers.filter(({ node }) => node.date.startsWith(year)).map(({ node }) => node)}
                           displayYear={false}/>
            </div>))}
        </section>


        <section id="education">
            <h2>
                <FontAwesomeIcon icon={faUserGraduate} className={"header-icon"}/>
                Education
            </h2>


            <ul className={enumeration}>
                {data.allEducationJson.edges.map(({ node }) => (<li key={node.id} className={item}>
                    <div className={metadata}>
                        <div className={title}>
                            {node.degree}
                            {node.degreeInfo ? <em className={titleInfo}>{" (" + node.degreeInfo + ")"}</em> : ""}
                        </div>
                        <div className={subtitle}>
                            {node.university}
                        </div>
                        <div className={info}>
                            {node.honors && <span>
                                    {node.honors}
                                .
                                </span>}
                            {node.info && <span className={additionalInfo}>{} {node.info}.</span>}
                            {/*{node.grade && <div className={styles.grade}>{node.grade}</div>}*/}
                        </div>

                    </div>
                    <div className={date}>
                        {node.years}
                    </div>

                </li>))}
            </ul>

        </section>


        <section id="teaching">
            <h2>
                <FontAwesomeIcon icon={faChalkboardTeacher} className={"header-icon"}/>
                Teaching
            </h2>

            <p>
                During my PhD, I have been a teaching assistant for the following courses:
            </p>

            <ul className={enumeration}>
                {data.allTeachingJson.edges.map(({ node }) => (<li key={node.id} className={item}>
                    <div className={metadata}>
                        <div className={title}>
                            {node.course}
                        </div>
                        <a href={node.url}
                           target="_blank"
                           rel="noopener noreferrer"
                           className={urlBadge}>
                            {node.code}
                        </a>
                        <div className={additionalInfo}>
                            {node.info}
                        </div>

                    </div>
                    <div className={date}>
                        {node.years}
                    </div>

                </li>))}
            </ul>
        </section>


        <section id="supervision">
            <h2>
                <FontAwesomeIcon icon={faHandsHelping} className={"header-icon"}/>
                Supervision
            </h2>

            <p>
                I supervised the following students' master's theses:
            </p>

            <ul className={enumeration}>
                {data.allSupervisionJson.edges.map(({ node }) => (<li key={node.id} className={item}>
                    <div className={metadata}>
                        <div className={title}>
                            {node.englishTitle}
                        </div>
                        {/*{node.paper && <a href={node.paper}*/}
                        {/*                target="_blank"*/}
                        {/*                rel="noopener noreferrer"*/}
                        {/*                className={styles.code}>*/}
                        {/*    paper*/}
                        {/*</a>}*/}
                        <div className={subtitle}>
                            {node.dutchTitle && <div className={additionalInfo}>
                                (NL) {node.dutchTitle}
                            </div>}
                            {node.student}
                            {/*{node.pdf && <a href={node.pdf}*/}
                            {/*                target="_blank"*/}
                            {/*                rel="noopener noreferrer"*/}
                            {/*                className={styles.code}>*/}
                            {/*    pdf*/}
                            {/*</a>}*/}
                        </div>

                    </div>
                    <div className={date}>
                        {node.year}
                    </div>

                </li>))}
            </ul>
        </section>


        <section id="awards">
            <h2>
                <FontAwesomeIcon icon={faTrophy} className={"header-icon"}/>
                Awards
            </h2>

            <ul className={enumeration}>
                {data.allAwardsJson.edges.map(({ node }) => (<li key={node.id} className={item}>
                    <div className={metadata}>
                        <div className={title}>
                            {node.title}
                            {node.titleInfo ? <em className={titleInfo}>{" (" + node.titleInfo + ")"}</em> : ""}
                        </div>
                        <div className={subtitle}>
                            {node.instance}
                        </div>
                        <div className={additionalInfo}>
                            {node.description}
                        </div>

                    </div>
                    <div className={date}>
                        {node.date}
                    </div>

                </li>))}
            </ul>

        </section>


    </>)
}

export default MediaPage

export const query = graphql`
  query {
    allPapersJson(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          ...PaperFragment
        }
      }
    }
    allEducationJson {
      edges {
        node {
          id
          degree
          degreeInfo
          grade
          honors
          university
          years
          info
        }
      }
    }
    allTeachingJson {
      edges {
        node {
          id
          course
          code
          url
          years
          info
        }
      }
    }
    allSupervisionJson(sort:{ fields: [year], order: DESC}) {
      edges {
        node {
          id
          student
          paper
          dutchTitle
          englishTitle
          pdf
          article
          year
        }
      }
    }
    allAwardsJson {
      edges {
        node {
          id
          date
          title
          instance
          description
          prize
          url
        }
      }
    }
  }
`
