// extracted by mini-css-extract-plugin
export var additionalInfo = "academic-module--additionalInfo--ba731";
export var backgroundColor = "#141821";
export var borderRadius = ".15rem";
export var contentBadge = "academic-module--contentBadge--0f157";
export var date = "academic-module--date--54d7b";
export var enumeration = "academic-module--enumeration--df343";
export var headerIcon = "academic-module--header-icon--cb944";
export var info = "academic-module--info--13009";
export var item = "academic-module--item--04fbc";
export var maxModalWidth = "48em";
export var metadata = "academic-module--metadata--a8d6e";
export var modalBackgroundColor = "rgba(20,24,33,.75)";
export var subtitle = "academic-module--subtitle--35a46";
export var tableOfContents = "academic-module--table-of-contents--ddf33";
export var tableOfContentsList = "academic-module--tableOfContentsList--44e22";
export var title = "academic-module--title--6505f";
export var titleInfo = "academic-module--title-info--003e6";
export var urlBadge = "academic-module--urlBadge--1fd56";